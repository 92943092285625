import { Controller } from '@hotwired/stimulus';

export default class extends Controller<HTMLDivElement> {
  urlValue!: string;
  markAsSharedFormTarget!: HTMLFormElement;

  static values = {
    url: String,
  };

  static targets = ['markAsSharedForm'];

  async share(): Promise<void> {
    try {
      this.element.classList.add('loading');
      const res = await fetch(this.urlValue);
      const blob = await res.blob();
      const filename =
        res.headers.get('Content-Disposition')?.match(/filename="(.+)"/)?.[1] ??
        this.urlValue.split('/').pop() ??
        'file.mp4';
      const file = new File([blob], filename, { type: blob.type });
      await navigator.share({ files: [file] });

      this.markAsSharedFormTarget.requestSubmit();
    } catch (error) {
      // catch the abort error
      if (error instanceof Error && error.name === 'AbortError') {
        // dismiss the error
      } else {
        throw error;
      }
    } finally {
      this.element.classList.remove('loading');
    }
  }

  async marked(): Promise<void> {
    this.element.dataset.markedAsShared = 'true';
  }
}
